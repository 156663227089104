<template>
  <div class="">
    <b-button
      variant="outline-primary"
      class="mt-0 mt-md-2 ml-4"
      @click="goBack()"
    >
      <!--<feather-icon icon="XIcon" class="mr-25" />-->
      <span>{{ $t('iframe.back') }}</span>
    </b-button>
    <b-carousel
      fade
      class="html_content"
      v-model="currentSlide"
      :interval="interval"
    >
      <div
        v-for="iframe in iframeData"
        v-bind:key="iframe.id"
        indicators
        controls
      >
        <b-carousel-slide
          img-blank
          img-alt="Vivozite Iframe"
          :img-width="calculatedWidth()"
          :img-height="calculatedHeight()"
        >
          <div class="d-flex justify-content-between mb-1">
            <div>
              <h4>
                {{ getTitle(iframe.type) }} {{ ' - ' + iframe.device.name }}
              </h4>
            </div>
            <div class="pr-1" style="font-size:16px;">
              {{ dateFrom(iframe.history) + ' - ' + dateTo() }}
            </div>
          </div>
          <component
            v-bind:is="iframe.type"
            :id="iframe.deviceId"
            :dateFrom="dateFrom(iframe.history)"
            :dateTo="dateTo()"
            :hideHeader="true"
          ></component>
        </b-carousel-slide>
      </div>
    </b-carousel>
  </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue';

import LoginForm from '@/views/login/LoginForm';
import ResetForm from '@/views/login/ResetForm';
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BCarousel,
  BCarouselSlide,
  BButton,
} from 'bootstrap-vue';

import { getUserData } from '@/auth/utils';
import SpeedingStats from '@/views/apps/speeding/CounterStats.vue';
import AverageStats from '@/views/apps/speeding/AverageStats.vue';
import PercentilStats from '@/views/apps/speeding/PercentilStats.vue';
import ClassStats from '@/views/apps/speeding/ClassStats.vue';
import CyclistStats from '@/views/apps/cyclist/CounterStats.vue';
import CyclistAverageStats from '@/views/apps/cyclist/AverageStats.vue';
import CyclistClassStats from '@/views/apps/cyclist/ClassStats.vue';
import axios from '@/libs/axios';

export default {
  components: {
    LoginForm,
    ResetForm,
    BRow,
    BCol,
    BImg,
    BLink,
    BCarousel,
    BCarouselSlide,
    BButton,

    VuexyLogo,
    SpeedingStats,
    AverageStats,
    PercentilStats,
    ClassStats,
    CyclistStats,
    CyclistAverageStats,
    CyclistClassStats,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login/login.svg'),
      iframeComponents: [
        'speeding-stats',
        'average-stats',
        'percentil-stats',
        'class-stats',
      ],
      showIframes: [],
      iframeData: [],
      currentSlide: 0,
    };
  },
  async mounted() {
    const iframe = await axios.get('iframe/hash/' + this.$route.params.hash);

    if (iframe.data) {
      iframe.data.iframe_data.forEach(element => {
        if (element.deviceId == this.$route.params.deviceId) {
          this.showIframes.push(element.type);
          this.iframeData.push(element);
        }
      });
      this.iframeData.sort(function(a, b) {
        return a.order - b.order;
      });
    }

    // this.showIframes = iframe.data[0].iframe_data;
  },
  methods: {
    goBack() {
      var getUrl = window.location;
      var baseUrl = getUrl.protocol + '//' + getUrl.host + '/';
      window.open(
        baseUrl + 'iframe/locations/' + this.$route.params.hash,
        '_self',
      );
    },
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
    dateFrom(history) {
      return this.$moment()
        .subtract(history, 'days')
        .format('DD.MM.YYYY HH:ss');
    },
    dateTo() {
      return this.$moment().format('DD.MM.YYYY HH:ss');
    },
    calculatedWidth() {
      return window.innerWidth;
    },
    calculatedHeight() {
      return window.innerHeight;
    },
    getTitle(componentName) {
      const titles = {
        'speeding-stats': this.$t('number_of_vehicle_approximately'),
        'average-stats': this.$t('speeding.average_speed'),
        'percentil-stats': this.$t('speed_percentiles'),
        'class-stats': this.$t('classes.speed_classes'),
      };
      return titles[componentName];
    },
  },
  computed: {
    imgUrl() {
      return this.sideImg;
    },
    interval() {
      if (this.currentSlide && this.iframeData) {
        return this.iframeData[this.currentSlide].duration * 1000;
      } else {
        return 5000;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.carousel-caption {
  color: #000;
}
div.html_content {
}
.carousel-indicators li {
  background-color: #0391e5 !important;
}
.carousel-caption {
  right: 5% !important;
  left: 5% !important;
}

.carousel-caption {
  position: absolute;
  top: 0;
}
</style>
